import React from "react";
import PropTypes from "prop-types";

import { REPORTS } from "../../content/reports";

import DownloadIcon from "../assets/icons/download.svg";

import { useTranslations } from "../hooks/translations";

import styles from "./report-list.module.scss";

const ReportList = ({ type }) => {
  const t = useTranslations("reports");

  return (
    <div className={styles.reportsContainer}>
      <h2>{t.types[type].plural}</h2>

      <ul className={styles.reports}>
        {REPORTS[type].map(({ year, file }) => (
          <li key={year} className={styles.report}>
            <span className={styles.reportName}>
              {t.types[type].singular} {year} {t.y}
            </span>
            <a
              href={file}
              download
              className={`bp-button bp-inverse ${styles.downloadButton}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <span className={styles.downloadLabel}>{t.download}</span>{" "}
              <DownloadIcon className="bp-stroke bp-1" />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

ReportList.propTypes = {
  type: PropTypes.string.isRequired
};

export default ReportList;
