import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import Modal from "./modal";

import styles from "./contact-modal.module.scss";

const ContactModal = ({ message, email, onClose }) => {
  const linkRef = useRef();

  useEffect(() => {
    // Somehow the autoFocus attribute does not work on links
    linkRef.current.focus();
  }, []);

  return (
    <Modal onClose={onClose}>
      <h1 className={styles.contactModalHeading}>{message}:</h1>

      <p className={styles.contactModalParagraph}>
        <a href={`mailto:${email}`} className="bp-link" ref={linkRef}>
          {email}
        </a>
      </p>
    </Modal>
  );
};

ContactModal.propTypes = {
  message: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ContactModal;
