import React, { useState } from "react";
import PropTypes from "prop-types";

import CONTACTS from "../../../content/contact.json";

import { useTranslations } from "../../hooks/translations";

import ButtonLink from "../button-link";
import Button from "../button";
import ContactModal from "../contact-modal";

const JoinUs = ({ heading: headingProvided, description: descriptionProvided }) => {
  const t = useTranslations("joinUs");

  const [contactModalVisible, setContactModalVisible] = useState(false);

  return (
    <>
      <section>
        <div className="bp-container">
          <h1>{headingProvided || t.heading}</h1>
          <p className="bp-subheading">{descriptionProvided || t.description}</p>
          <div className="bp-cards bp-3">
            <div className="bp-card">
              <h2>{t.joinVolunteers.heading}</h2>
              <p>{t.joinVolunteers.description}</p>
              <ButtonLink className="bp-inverse" to="/register">
                {t.joinVolunteers.cta}
              </ButtonLink>
            </div>

            <div className="bp-card">
              <h2>{t.becomePartner.heading}</h2>
              <p>{t.becomePartner.description}</p>
              <Button
                className="bp-inverse"
                onClick={() => setContactModalVisible(true)}
                aria-haspopup="dialog"
                aria-expanded={!!contactModalVisible}
              >
                {t.becomePartner.cta}
              </Button>
            </div>

            <div className="bp-card">
              <h2>{t.donate.heading}</h2>
              <p>{t.donate.description}</p>
              <ButtonLink className="bp-inverse" to="/donate">
                {t.donate.cta}
              </ButtonLink>
            </div>
          </div>
        </div>
      </section>

      {contactModalVisible ? (
        <ContactModal
          onClose={() => setContactModalVisible(false)}
          message={t.contactModalHeading}
          email={CONTACTS.partnersEmail}
        />
      ) : null}
    </>
  );
};

JoinUs.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string
};

JoinUs.defaultProps = {
  heading: "",
  description: ""
};

export default JoinUs;
