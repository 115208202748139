import React from "react";
import { graphql } from "gatsby";

import { REPORT_TYPE } from "../../../content/reports";

import { useTranslations } from "../../hooks/translations";
import SEO from "../../components/seo";
import JoinUs from "../../components/about/join-us";
import ReportList from "../../components/report-list";

export const query = graphql`
  query ReportsQuery($locale: String!) {
    translations(locale: $locale) {
      pages {
        about {
          reports
        }
      }
      reports
      joinUs
      ...LayoutTranslations
    }
  }
`;

const ReportsPage = () => {
  const t = useTranslations("pages.about.reports");

  return (
    <>
      <SEO title={t.title} description={t.metaDescription} />

      <section>
        <h1>{t.heading}</h1>

        <div className="bp-container">
          {Object.values(REPORT_TYPE).map(type => (
            <ReportList key={type} type={type} />
          ))}
        </div>
      </section>

      <JoinUs />
    </>
  );
};

export default ReportsPage;
